<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
      <!-- <CIcon
        custom-class-name="sidebar-brand-full"
        :icon="logoNegative"
        :height="35"
      />
      <CIcon
        custom-class-name="sidebar-brand-narrow"
        :icon="sygnet"
        :height="35"
      /> -->
      <CImage v-show="webName == 'banpong168'" fluid :src="logoBanpong168" />
      <CImage v-show="webName == 'goodluck711'" fluid :src="logoGoodluck711" />
      <CImage v-show="webName == 'tinder888'" fluid :src="logoTinder888" />
      <CImage v-show="webName == 'loso168'" fluid :src="logoLoso168" />
    </CSidebarBrand>
    <AppSidebarNav />
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { mapActions, useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'

// import { logoNegative } from '@/assets/brand/logo-negative'
// import logoNegative from '@/assets/brand/header-logo-03.png'
import logoBanpong168 from '@/assets/brand/header-logo-banpong168.png'
import logoGoodluck711 from '@/assets/brand/header-logo-goodluck711.png'
import logoTinder888 from '@/assets/brand/header-logo-tinder888.png'
import logoLoso168 from '@/assets/brand/header-logo-loso168.png'

import { sygnet } from '@/assets/brand/sygnet'

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  data() {
    return {
      currentWebAgent: '',
      optWebAgent: [],
      webName: '',
    }
  },
  methods: {
    ...mapActions({
      tokenExpired: 'auth/tokenExpired',
    }),
    navigateTo(route) {
      this.$router.push(route)
    },

    // api
    async getWebPrefixList() {
      await this.$http
        .post('panel/getprefix', {})
        .then((response) => {
          if (response.data.status == 200) {
            this.optWebAgent = response.data.result_perfix
            this.currentWebAgent = this.optWebAgent[0]._id
            console.log(this.optWebAgent)
          } else if (
            response.data.status == 502 ||
            response.data.status == 503
          ) {
            this.tokenExpired().then(() => {
              this.navigateTo('/pages/login')
            })
          } else {
            console.log(
              'call api - panel/getprefix : status = ' +
                response.data.status +
                ', message = ' +
                response.data.message,
            )
          }
        })
        .catch((error) => {
          console.log('call api - panel/getprefix : error' + error)
        })
    },
  },
  created() {
    this.getWebPrefixList().then(() => {
      this.webName = this.optWebAgent[0].name
      document.title =
        this.optWebAgent[0].name.toUpperCase() + ' Transaction Center'
    })
  },
  setup() {
    const store = useStore()
    return {
      logoBanpong168,
      logoGoodluck711,
      logoTinder888,
      logoLoso168,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>
