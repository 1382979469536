<template>
  <CFooter>
    <div>
      <span class="ms-1">
        Copyright &copy;
        <small>
          <!-- Build: 2022-08-28 (Version: 1.1.0) | Auto Banking Transfer API By -->
          Build: 2022-09-20 (Version: 1.2.0) | Auto Banking Transfer API By
          tttAPI.
        </small>
      </span>
    </div>
    <!-- <div class="ms-auto">
      <span class="me-1" target="_blank">Powered by</span>
      <a href="https://coreui.io/vue">CoreUI for Vue</a>
    </div> -->
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
