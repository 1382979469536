//Size of the icon. Available sizes: 'sm', 'lg', 'xl', 'xxl', '3xl...9xl', 'custom', 'custom-size'

import { cibFacebook, cibLine } from '@coreui/icons'
import { cifUs } from '@coreui/icons'
import {
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilBan,
  cilBasket,
  cilBell,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCursor,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMediaPlay,
  cilMenu,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPuzzle,
  cilSettings,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilTask,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilXCircle,
  cilInstitution,
  cilBraille,
  cilFolderOpen,
  cilGift,
  cilContact,
  cilUserPlus,
  cilChild,
  cilGroup,
  cilRoom,
  cilClearAll,
  cilArrowThickToBottom,
  cilArrowThickToTop,
  cilBank,
  cilCircle,
  cilCheckAlt,
  cilX,
  cilLoopCircular,
  cilCash,
  cilExternalLink,
  cilReload,
  cilFilter,
  cilFullscreen,
  cilSave,
  cilFingerprint,
  cilFullscreenExit,
  cilClock,
  cilBirthdayCake,
  cilCreditCard,
  cilPhone,
  cilLink,
  cilThumbUp,
  cilThumbDown,
  cilSearch,
  cilAddressBook,
  cilClone,
  cilColorBorder,
  cilCopy,
  cilHistory,
  cilQrCode,
  cilBarcode,
  cilZoomIn,
  cilTransfer,
  cilWallet,
  cilPaperPlane,
  cilArrowThickFromLeft,
  cilShareAlt,
  cilTerminal,
  cilSmilePlus,
  cilPowerStandby,
  cilBalanceScale,
  cilLibraryAdd,
  cilPlaylistAdd,
  cilCalendarCheck,
  cilPlus,
  cilChartLine,
} from '@coreui/icons'

export const iconsSet = Object.assign(
  {},
  {
    cilArrowBottom,
    cilArrowRight,
    cilArrowTop,
    cilBan,
    cilBasket,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilCode,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMediaPlay,
    cilMenu,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPuzzle,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cilInstitution,
    cilBraille,
    cilFolderOpen,
    cilGift,
    cilContact,
    cilUserPlus,
    cilChild,
    cilGroup,
    cilRoom,
    cilClearAll,
    cilArrowThickToBottom,
    cilArrowThickToTop,
    cilBank,
    cilCircle,
    cilCheckAlt,
    cilX,
    cilLoopCircular,
    cilCash,
    cilExternalLink,
    cilReload,
    cilFilter,
    cilFullscreen,
    cilSave,
    cilFingerprint,
    cilFullscreenExit,
    cilClock,
    cilBirthdayCake,
    cilCreditCard,
    cilPhone,
    cilLink,
    cilThumbUp,
    cilThumbDown,
    cilSearch,
    cilAddressBook,
    cilClone,
    cilColorBorder,
    cilCopy,
    cilHistory,
    cilQrCode,
    cilBarcode,
    cilZoomIn,
    cilTransfer,
    cilWallet,
    cilPaperPlane,
    cilArrowThickFromLeft,
    cilShareAlt,
    cilTerminal,
    cilSmilePlus,
    cilPowerStandby,
    cilBalanceScale,
    cilLibraryAdd,
    cilPlaylistAdd,
    cilCalendarCheck,
    cilPlus,
    cilChartLine,
  },
  {
    cifUs,
  },
  {
    cibFacebook,
    cibLine,
  },
)
